// @ts-nocheck
import Image from '@editorjs/image';
// import Paragraph from 'editorjs-paragraph-with-alignment';
import List from '@editorjs/list';
import Header from '@editorjs/header';
import Embed from '@editorjs/embed';
import Paragraph from '@editorjs/paragraph';
import Table from '@editorjs/table';
import HyperLink from 'editorjs-hyperlink';
import ColorPlugin from 'editorjs-text-color-plugin';
import { apiUrl } from './apiUrl';

export interface EditorJsToolsProps {
  _id: string;
  wysiwygType?: 'article' | 'contest' | 'temporary-action';
}

const commonTools = {
  Color: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      colorCollections: [
        '#EC7878',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#0070FF',
        '#03A9F4',
        '#00BCD4',
        '#4CAF50',
        '#8BC34A',
        '#CDDC39',
        '#FFF',
      ],
      defaultColor: '#FF1300',
      type: 'text',
      customPicker: true
    },
  },
  Marker: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      defaultColor: '#FFBF00',
      type: 'marker',
      customPicker: true
    },
  },
};

export const editorJsTools = ({ _id, wysiwygType }: EditorJsToolsProps) => {
  const modulesWithoutImage = {
    ...commonTools,
    list: {
      class: List,
      styles: ['unordered', 'ordered'],
    },
    header: {
      class: Header,
      levels: [2, 3, 4],
    },
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
          vimeoEvent: {
            regex:
              /(?:http[s]?:\/\/)?(?:www.)?vimeo\.co(?:.+\/event\/([^\/]\d+)(?:#t=[\d]+)?s?(\/embed)?$)/,
            embedUrl: 'https://vimeo.com/event/<%= remote_id %>/embed',
            html: '<iframe style="width:100%;" height="320" frameborder="0" mozallowfullscreen webkitallowfullscreen allowfullscreen style="--aspect-ratio: 320 / 560;"></iframe>',
            height: 320,
            width: 580,
          },
          vimeo: true,
        },
      },
    },
    paragraph: {
      class: Paragraph,
    },
    table: {
      class: Table,
      config: {
        rows: 1,
        cols: 1,
      },
    },
    hyperlink: {
      class: HyperLink,
      config: {
        shortcut: 'CMD+L',
        target: '_blank',
        rel: 'nofollow',
        availableTargets: ['_blank', '_self'],
        availableRels: ['author', 'noreferrer'],
      },
    },
  };

  const url = `${apiUrl}${wysiwygType}/add-single-photo/${_id}?tenantId=${_id}`;

  const modulesWithImage = {
    ...commonTools,
    image: {
      class: Image,
      inlineToolbar: [],
      config: {
        additionalRequestHeaders: {
          'with-credentials': true,
        },
        endpoints: {
          byFile: url, // Your backend file uploader endpoint
          byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
        },
      },
    },
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
          vimeoEvent: {
            regex:
              /(?:http[s]?:\/\/)?(?:www.)?vimeo\.co(?:.+\/event\/([^\/]\d+)(?:#t=[\d]+)?s?(\/embed)?$)/,
            embedUrl: 'https://vimeo.com/event/<%= remote_id %>/embed',
            html: '<iframe style="width:100%;" height="320" frameborder="0" mozallowfullscreen webkitallowfullscreen allowfullscreen style="--aspect-ratio: 320 / 560;"></iframe>',
            height: 320,
            width: 580,
          },
          vimeo: true,
          // vimeo: {
          //   regex: /(?:http[s]?:\/\/)?(?:www.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
          //   embedUrl: 'https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0',
          //   html: '<iframe style="width:100%;" height="320" frameborder="0" mozallowfullscreen webkitallowfullscreen allowfullscreen style="--aspect-ratio: 320 / 560;"></iframe>',
          //   height: 320,
          //   width: 560,
          // },
        },
      },
    },
    list: {
      class: List,
      styles: ['unordered', 'ordered'],
    },
    header: {
      class: Header,
      levels: [2, 3, 4],
    },
    paragraph: {
      class: Paragraph,
    },
    table: {
      class: Table,
      config: {
        rows: 1,
        cols: 1,
      },
    },
    hyperlink: {
      class: HyperLink,
      config: {
        shortcut: 'CMD+L',
        target: '_blank',
        rel: 'nofollow',
        availableTargets: ['_blank', '_self'],
        availableRels: ['author', 'noreferrer'],
      },
    },
  };
  return !wysiwygType ? modulesWithoutImage : modulesWithImage;
};
